.SleepSessionsAdd {

	.react-datepicker {
		width: max-content;
	}

	&_Date {
		display: grid;
		align-items: center;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
	}

	&_Form {
		display: grid;
		width: 100%;
		max-width: 370px;
		margin: 0 auto;
		gap: 37px;
		margin-top: 100px;
	}

	&_Error {
		display: grid;
		grid-auto-flow: column;
		justify-content: start;
		gap:12px;
		color: var(--error-color);
	}

	&_Buttons {
		display: grid;
		grid-auto-flow: column;
		gap: 10px;
		justify-content: center;
		margin-top: 30px;
	}
}