.DreamInterpretation {

	.HistoryBackLink {
		margin-bottom: 32px;
	}

	.H1 {
		display: grid;
		align-items: center;
		align-content: center;
		grid-template-columns: auto 1fr;
		gap: 10px;

		.MaterialSymbol {
			font-size: 36px;
			display: grid;
			width: 46px;
			height: 46px;
			border-radius: 8px;
			background: #FCE0EA;
			color: var(--primary-color);
			align-items: center;
			text-align: center;
		}
	}

	&_Dream {
		display: grid;
		width: 100%;
		max-width: 900px;
		margin: 0 auto;
		gap: 20px;

		&_Header {
			white-space: pre-line;
		}
	}
}
