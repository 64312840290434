.SleepQualitySelector {
	display: grid;

	&_Label {
		display: grid;
		grid-auto-flow: column;
		font-size: 18px;
		margin-bottom: 10px;
		gap: 6px;
		justify-items: start;
		justify-content: start;
		align-items: center;

		.MaterialSymbol {
			color: var(--input-icon-color);
			font-size: 18px;
			line-height: 18px;
		}

		label {
			color: var(--primary-color);
			white-space: nowrap;
			font-size: 16px;
			font-weight: 400;
		}

		&_Help {
			cursor: help;
			justify-self: end;

			&:hover {
				color: var(--highlight-color);
			}
		}
	}

	&_Options {
		display: grid;
		grid-auto-flow: column;
		gap: 12px;
		justify-content: start;
	}

	&_Option {
		display: grid;
		align-items: center;
		justify-items: center;
		width: 38px;
		height: 38px;
		border: 1px solid #CDCDD6;
		border-radius: 50%;
		font-weight: 400;
		font-size: 22px;

		&--selected {
			border: 1px solid var(--highlight-color);
			background: #FCE0EA;
		}
	}

	.selected {
		display: none;
	}

	label {
		cursor: pointer;
	}

	input {
		display: none;
	}

	label input:checked ~ .selected {
		display: inline-block;
	}
	label input:checked ~ .not-selected {
		display: none;
	}
}
