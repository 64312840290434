.TypingDots {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 50px;

	.MaterialSymbol {
		font-size: 16px;
		animation: blink 1.5s infinite;
		opacity: 0.4;
	}

	.MaterialSymbol:nth-child(1) {
		animation-delay: 0s;
	}

	.MaterialSymbol:nth-child(2) {
		animation-delay: 0.2s;
	}

	.MaterialSymbol:nth-child(3) {
		animation-delay: 0.4s;
	}

	@keyframes blink {
		0% {
			opacity: 0.4;
		}

		50% {
			opacity: 1;
			transform: translateY(-2px);
		}

		100% {
			opacity: 0.4;
			transform: translateY(0);
		}
	}
}