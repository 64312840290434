.DreamCard {
	display: grid;
	background-color: #fff;
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.09);
	border-radius: 8px;
	padding: 24px;
	gap: 12px;

	&_SubTitle {
		color: #6f8495;
	}

	&_Section {
		display: grid;
		gap: 12px;

		.P {
			margin: 0;
		}

		&_List {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			justify-content: flex-start;
			width: 100%;
		}
	}

	&_Actions {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr 1fr;
		gap: 12px;
		margin-top: 12px;

		.Button[color=light] {

			&:hover,
			&:focus {
				background-color: var(--button-dark-blue-bg-hover);
				color: var(--button-dark-blue-color-hover);
				border-color: var(--button-dark-blue-border-color-hover);
			}
		}
	}

	&_Interpretation {
		display: grid;
		grid-template-columns: 24px 1fr;
		gap: 8px;
		cursor: pointer;

		&_Name {
			font-weight: 400;
			color: #0F3652;
			font-size: 16px;
		}

		.MaterialSymbol {
			background: #FCE0EA;
			color: #0F3652;
			width: 24px;
			height: 24px;
			padding: 4px;
			border-radius: 5.33px;
			font-size: 16px;
		}

		&:hover {
			.DreamCard_Interpretation_Name {
				color: var(--highlight-color);
			}
			.MaterialSymbol {
				background-color: var(--highlight-color);
				color: #fff;
			}
		}
	}
}