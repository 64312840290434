@import '../../../ruya-shared/shared/assets/mixins';

.InterpreterSelection {
	overflow: auto;

	&_Explanation {
		margin-bottom: 32px;
	}

	// &_Interpreters {
	// 	display: grid;
	// 	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	// 	gap: 32px;
	// 	width: 100%;
	// 	justify-content: start;
	// }

	&_InterpreterSlider {
		position: relative;
		padding-top: 150px;

		@include minMobile {
			padding-top: 60px;
		}

		@include minMobile {
			border: 1px solid rgba(15, 54, 82, 0.084);
			border-top: none;
			border-bottom: none;
			border-radius: 12px;
		}

		.glide {
			position: relative;

			&__arrows {
				position: absolute;
				display: grid;
				grid-auto-flow: column;
				justify-content: space-between;
				top: calc(50% - 100px);
				width: 100%;

				@include minMobile {
					padding: 10px;
				}
			}

			&__arrow {
				z-index: 1;
				border-radius: 12px;
				border: 1px solid #FCE0EA;
				width: 30px;
				height: 100px;
				background-color: #ffffff82;
				text-align: center;
				font-size: 100%;
				color: #ff0059;

				@include minMobile {
					width: 50px;
					color: #FCE0EA;
				}

				&:hover {
					cursor: pointer;
					color: #ff0059; 
					border-color: #fff;
					box-shadow: #FCE0EA 0px 0px 30px;
					text-shadow: #FCE0EA 0px 0px 30px;
				}
				

				&--prev {}

				&--next {}
			}

			&__track {
				cursor: grab;

				&:active {
					cursor: grabbing;
				}
			}

			&__slides {
				overflow: visible;
				transform-style: preserve-3d;
			}

			&__slide {
				padding: 10px;

				@include minMobile {
					padding: 40px;
				}

				transform: perspective(2000px);
			}

			&__bullets {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
				position: absolute;
				justify-content: center;
				justify-items: center;
				top: -120px;
				width: 100%;
				margin: 0 auto;
				z-index: 1;

				@include minMobile {
					grid-template-columns: unset;
					grid-auto-flow: column;
					top: -40px;
				}
			}

			&__bullet {
				display: grid;
				width: 60px;
				height: 60px;
				border-radius: 8px;
				font-size: 34px;
				color: var(--primary-color);
				align-items: center;
				text-align: center;
				cursor: pointer;

				&:hover {
					color: #ff0059;
					transform: scale(1.2);
				}

				&--active {
					color: #ff0059;
					transform: scale(1.2);
				}
			}
		}

		.Interpreter {
			transition: all 500ms ease;

			&:hover {
				box-shadow: #FCE0EA 0px 0px 30px;
			}

			&--prev {
				filter: blur(2px);
				opacity: 0.5;
			}

			&--next {
				filter: blur(2px);
				opacity: 0.5;
			}
		}
	}

	&_Error {
		color: var(--error-color);
		margin: 0;
	}

	&_Dream {
		display: grid;
		padding: 10px;
		background-color: #fafafa;
		border-bottom: 1px solid #e0e0e0;

		&_Info {
			display: grid;
			grid-auto-flow: column;
			gap: 10px;
			align-items: center;
			background-color: #e5e5e5;
		}

		&_Content {
			padding: 10px;
			white-space: pre-line;
		}
	}
}
