.AccountStatus {
	&_NotActive {
		margin: 50px auto 0 auto;
		width: 100%;
		max-width: 600px;
	}

	&_Blocked {
		margin: 50px auto 0 auto;
		width: 100%;
		max-width: 600px;
	}

	&_Tips {
		li {
			margin-bottom: 8px;
		}
	}
}
