@import '../../../ruya-shared/shared/assets/mixins';

.DreamView {

	.ContentWrapper_Content {
		display: grid;
		width: 100%;
		max-width: 900px;
		gap: 136px;
	}

	&_Content {

		&_TopBar {
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			width: 100%;
		}

		&_Title {
			margin-bottom: 12px;
		}

		&_Date {
			font-weight: 500;
			margin: 0 0 24px
		}

		&_DreamInfo {
			display: grid;
			grid-auto-flow: column;
			gap: 24px;
			align-items: center;
			padding: 24px;
			border: 1px solid hsla(240, 10%, 82%, 0.5);
			border-radius: 12px;
			margin-bottom: 24px;
			color: var(--primary-color);
			justify-content: start;
			font-size: 12px;

			span {
				display: grid;
				grid-template-columns: 24px 1fr;
				gap: 8px;
				align-items: center;

				.MaterialSymbol {
					color: var(--highlight-color);
				}
			}
		}

		&_Dream {
			font-weight: 400;
			font-size: 16px;
			line-height: 25.6px;
			margin-bottom: 24px;
			white-space: pre-wrap;
		}
	}

	&_Interpretations {
		display: grid;
		grid-auto-flow: row;
		gap: 12px;

		&_List {
			display: grid;
			gap: 12px;

			&_Item {
				display: grid;
				grid-template-columns: 34px 1fr;
				gap: 8px;
				cursor: pointer;
				background-color: var(--button-light-bg-hover);
				border-radius: var(--button-md-border-radius);
				padding: var(--button-md-padding);
				align-items: center;

				&_Name {
					font-weight: 400;
					color: #0F3652;
					font-size: 16px;
				}

				.MaterialSymbol {
					background: #FCE0EA;
					color: #0F3652;
					width: 34px;
					height: 34px;
					padding: 4px;
					border-radius: 5.33px;
					font-size: 26px;
				}

				&:hover {
					.DreamView_Interpretations_List_Item_Name {
						color: var(--highlight-color);
					}

					.MaterialSymbol {
						background-color: var(--highlight-color);
						color: #fff;
					}
				}
			}
		}
	}

}