@import '../../../assets/mixins';

.Logo {
	display: grid;
	grid-auto-flow: row;
	gap: 3px;
	cursor: pointer;
	width: max-content;
	text-align: start;
	align-self: self-start;

	img {
		width: 75px;

		@include minDesktop {
			width: 100px;
			height: auto;
		}
	}

	&_Slogan {
		position: relative;
		display: inline-block;
		font-size: 0.6em;
		color: #666;
		margin: 0;
		padding: 0;
		width: 100%;

		@include minDesktop {
			font-size: 0.7em;
		}

		&_Letter {
			display: inline-block;
			line-height: 1em;
			transform-origin: 0 0;
			opacity: 0;
		}
	}

	&:hover {
		&_Slogan {
			color: darken(#666, 10%);
		}
	}
}
