@import '../../../assets/variables';
@import '../../../assets/mixins';

.ContentWrapper {
	width: 100%;
	max-width: $content-max-width;
	padding: $content-padding-mobile;
	margin: 0 auto;

	@include minDesktop {
		padding: $content-padding;
	}
}
