.DreamQA {
	height: 400px;
	background: #ffffff50;
	border-radius: 12px;
	border: 1px #ffdbe7 solid;

	&_InnerContainer {
		position: relative;
		height: 100%;
	}

	&_InnerScroll {
		overflow-y: scroll;
		padding: 10px;
		height: calc(100% - 70px);
	}
	
	&_Chat {
		display: grid;
		align-content: end;

		&_Item {
			display: grid;
			width: auto;
			max-width: 700px;
			font-size: 16px;
			margin-bottom: 32px;
			gap:12px;

			.MaterialSymbol {
				display: grid;
				width: 36px;
				height: 36px;
				border-radius: 8px;
				font-size: 24px;
				background: #FCE0EA;
				color: var(--primary-color);
				align-items: center;
				text-align: center;
			}

			&--user {
				justify-self: end;
				text-align: right;
				grid-template-columns: 1fr auto;
			}

			&--assistant {
				justify-self: start;
				grid-template-columns: auto 1fr;
			}

			&_Image {

			}

			&_Message {
				background-color: #fff;
				border-radius: 12px;
				padding: 24px;
			}
		}

		&_Dream {
			display: grid;
			width: auto;
			font-size: 16px;
			margin-bottom: 32px;
			gap:12px;
			background-color: #fff1f1;
			border-radius: 12px;
			padding: 24px;
			white-space: pre-wrap;
		}

		&_Typing {
			margin-bottom: 20px;
		}	
	}

	&_Input {
		padding: 10px;
		height: 70px;
		border-top: 1px #ffdbe7 solid;
		position: absolute;
		bottom: 0;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 8px;
		align-items: center;
		width: 100%;
	}

	.TypingDots {
		color: #ffb8d1;
	}
}
