@import '../../../ruya-shared/shared/assets/mixins';

.NavigationSettings {
	display: none;
	position: relative;
	justify-self: end;

	@include minDesktop {
		display: block;
	}

	&--open {
		.NavigationSettings_Menu {
			display: grid;
		}

		.NavigationSettings_User {
			background-color: var(--navigation-link-active-bg);
			border: 1px var(--navigation-link-hover-bg) solid;

			&:hover {
				background-color: var(--navigation-link-active-bg);
			}
		}
	}

	&_User {
		display: grid;
		user-select: none;
		grid-auto-flow: column;
		border-radius: var(--form-button-border-radius);
		border: var(--form-element-border);
		padding: 7px 24px;
		gap: 10px;
		align-items: center;
		align-content: center;
		justify-content: center;
		text-align: center;
		text-decoration: none !important;
		color: var(--primary-color);
		font-size: 16px;
		line-height: normal;
		white-space: nowrap;
		cursor: pointer;
		max-height: 58px;

		&:hover {
			text-decoration: none !important;
			background: var(--navigation-link-hover-bg);
			border: 1px var(--navigation-link-hover-bg) solid;
		}

		&_Arrow {
			font-size: 36px;
		}
	}

	&_Menu {
		display: none;
		position: absolute;
		top: 70px;
		right: 0;
		background-color: #fff;
		border: var(--form-element-border);
		border-radius: 16px;
		box-shadow: var(--form-element-box-shadow);
		padding: 4px;
		z-index: 1;
		border: 1px solid #fff;
		user-select: none;
		gap: 2px;

		&.isOpen {
			display: block;
		}

		&_Item {
			display: grid;
			gap: 10px;
			color: var(--primary-color);
			text-decoration: none;
			cursor: pointer;
			text-wrap: nowrap;
			align-items: center;
			justify-items: start;
			border-radius: 12px;
			font-size: 14px;

			.ReactLink {
				display: grid;
				padding: 14px 12px;
				color: var(--primary-color);
				text-decoration: none;
				align-items: center;
				text-align: start;
				gap: 10px;
				width: 100%;
				border-radius: 12px;

				&--icon {
					grid-template-columns: 2em 1fr;
				}

				&--active {
					background-color: var(--secondary-bg);
				}

				&:hover {
					background-color: inherit;
				}
			}

			&:hover {
				background-color: var(--secondary-bg);
			}
		}
	}
}
