@import '../../../ruya-shared/shared/assets/mixins';

.DreamAdd {

	.react-datepicker {
		width: max-content;
	}

	&_Date {
		display: grid;
		align-items: center;
		cursor: pointer;
		height: 52px;
		font-size: 16px;
		padding: 6px 4px;
		font-weight: normal;
	}

	&_Form {
		width: 100%;
		max-width: 900px;
		margin: 24px auto 0 auto;

		@include minTablet {
			margin-top: 100px;
		}

		&_Error {
			color: var(--error-color);
		}

		&_Options {
			display: grid;
			grid-auto-flow: column;
			gap: 30px;
			margin-top: 30px;
			justify-content: start;
		}

		&_Buttons {
			display: grid;
			grid-auto-flow: column;
			gap: 10px;
			justify-content: start;
			margin-top: 40px;
			margin-bottom: 20px;
		}
	}

	&_DreamOptionsGroup {
		display: grid;
		grid-auto-flow: row;
		gap: 12px;
		margin-bottom: 24px;

		@include minTablet {
			grid-auto-flow: column;
			justify-content: start;
			gap: 32px;
		}

		.Switch {
			display: grid;
			grid-auto-flow: column;
			gap: 12px;
			justify-content: space-between;
	
			@include minTablet {
				justify-content: start;
				align-content: center;
	
				&_Label {
					margin-bottom: 0;
				}
			}
		}
	}

	&_RemoveDream {
		align-self: end;
		justify-self: end;
	}

	&_Buttons {
		display: grid;
		grid-auto-flow: column;
		gap: 10px;
		justify-content: start;
		margin-top: 20px;
	}
}