@import '../../../ruya-shared/shared/assets/mixins';

.Subscriptions {
	&_BillingCycle {
		display: grid;
		grid-auto-flow: column;
		gap: 20px;
		margin-bottom: 20px;
		justify-content: center;
	}

	&_Selections {
		display: grid;
		grid-auto-flow: row;
		gap: 40px;
		justify-content: center;

		@include minDesktop {
			grid-auto-flow: column;
		}
	}

	&_Package {
		display: grid;
		border: 1px solid #e5e5e5;
		padding: 10px;
		border-radius: 8px;
		background-color: #ffffff;
		cursor: pointer;
		grid-template-rows: min-content min-content min-content 1fr min-content;

		@include minDesktop {
			width: 300px;
		}

		&--selected {
			border: 1px var(--highlight-color) solid;
			box-shadow: 0 0 10px 0 var(--highlight-color);
		}

		&_Details {
		}

		&_Price {
			font-size: 24px;
			font-weight: 700;
			color: var(--highlight-color);

			p {
				margin: 0;
			}

			&--discount {
				display: grid;
				gap: 5px;
				grid-auto-flow: row;
				text-align: center;

				.Subscriptions_Package_Discount {
					display: grid;
					gap: 5px;
					grid-auto-flow: column;
					text-align: center;

					p:first-child {
						text-decoration: line-through;
						color: var(--primary-color);
					}
				}
			}
		}

		&_ButtonContainer {
			text-align: center;
		}
	}
}
