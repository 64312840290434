@import '../../../assets/mixins';

.H3 {
	font-weight: 600;
	font-size: 24px;
	line-height: 33.6px;
	color: var(--primary-color);

	@include minDesktop {
		font-size: 24px;
		line-height: 33.6px;
	}

	&--centered {
		text-align: center;
	}
}
