.Activation {
	&_Desc {
		text-align: center;
	}

	&_Error {
		text-align: center;
		color: var(--error-color);
	}
}
