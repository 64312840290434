.Label {
	font-weight: 500;

	&--md {
		font-size: 14px;
		line-height: 19.07px;
	}

	&--lg {
		font-size: 16px;
		line-height: 21.79px;
	}

	&--sm {
		font-size: 12px;
		line-height: 16.34px;
	}
}