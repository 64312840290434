@import '../../../assets/mixins';

.TimelineSeparator {
	display: block;
	height: 69px;
	width: 1px;
	margin: 0 auto;
	border-left: 1px #CDCDD6 solid;

	@include minTablet {
		height: 82px;
	}

	&--sm {
		height: 20px;
		border-left: 1px rgba(0, 0, 0, 0.1) solid;
	}
}