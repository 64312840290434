@import '../../../ruya-shared/shared/assets/mixins';

.ChangePassword {
	&_Desc {
		text-align: center;
	}

	&_Form {
		margin: 50px auto 0 auto;
		display: grid;
		grid-auto-flow: row;
		gap: 8px;
		width: 100%;
		max-width: 370px;
	}

	&_Success {
		margin: 50px auto 0 auto;
		display: grid;
		grid-auto-flow: row;
		gap: 8px;
		width: 100%;
		max-width: 370px;
	}

	&_Error {
		text-align: center;
		font-size: 16px;
		color: var(--error-color);
		margin: 0;
	}

	&_CaptchaError {
		color: var(--warning-color);
		margin: 0;
	}
}
