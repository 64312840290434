.DreamViewInterpretation {

	&_Content {

		&_Tabs {
			margin-bottom: 24px;

			.StackButtons {
				justify-content: start;
			}
		}

		&_Interpretation {
			font-weight: 400;
			font-size: 16px;
			line-height: 25.6px;
			margin-bottom: 24px;
			white-space: pre-wrap;
			max-width: 650px;
			padding: 24px;
			border-radius: 12px;
			background-color: #fff;
		}

		&_Dream {
			font-weight: 400;
			font-size: 16px;
			line-height: 25.6px;
			margin-bottom: 24px;
			white-space: pre-wrap;
			max-width: 650px;
			padding: 24px;
			border-radius: 12px;
			background-color: #fff;
		}

		&_DreamQA {
			display: grid;
			align-content: end;
			max-width: 650px;

			&_Item {
				display: grid;
				width: auto;
				max-width: 700px;
				font-size: 16px;
				margin-bottom: 32px;
				gap: 12px;

				.MaterialSymbol {
					display: grid;
					width: 36px;
					height: 36px;
					border-radius: 8px;
					font-size: 24px;
					background: #FCE0EA;
					color: var(--primary-color);
					align-items: center;
					text-align: center;
				}

				&--user {
					justify-self: end;
					text-align: right;
					grid-template-columns: 1fr auto;

					.DreamViewInterpretation_Content_DreamQA_Item_Message {
						border: 1px solid #CDCDD6;
						background-color: transparent;
					}
				}

				&--assistant {
					justify-self: start;
					grid-template-columns: auto 1fr;
				}

				&_Message {
					background-color: #fff;
					border-radius: 12px;
					padding: 24px;
				}
			}
		}
	}

	&_Error {
		color: var(--error-color);
	}
}