@import '../../../assets/mixins.scss';

.RangeSlider {
	&_Input {
		display: none;
	}

	&_Label {
		display: grid;
		grid-auto-flow: column;
		font-size: 18px;
		margin-bottom: 10px;
		gap: 6px;
		justify-content: start;
		align-items: center;

		.MaterialSymbol {
			color: var(--input-icon-color);
			font-size: 18px;
			line-height: 18px;
		}

		label {
			color: var(--primary-color);
			white-space: nowrap;
			font-size: 16px;
			font-weight: 400;
		}

		&_Help {
			cursor: help;
			justify-self: end;

			&:hover {
				color: var(--highlight-color);
			}
		}
	}

	&_UI {
		display: grid;
		height: 44px;
		align-items: center;
		position: relative;
		margin: 0 50px;

		&_TrackBg {
			position: absolute;
			left: -50px;
			top: 16px;
			background: linear-gradient(to right, var(--highlight-color) 50%, #fff 0%);
			border-radius: 100px;
			width: calc(100% + 100px);
			height: 12px;
			z-index: -1;
		}

		&_Track {
			height: 12px;
			background-color: #fff;

			&-fill {
				height: 12px;
				background-color: var(--highlight-color);
			}
		}

		&_Thumb {
			position: absolute;
			display: grid;
			align-content: center;
			width: min-content;
			height: 26px;
			border-radius: 100px;
			background-color: #fff;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
			cursor: pointer;
			text-align: center;
			transform: translateX(-50%);
			z-index: 2;
			top: 8px;
			white-space: nowrap;
			padding: 6px 12px;

			&:hover {
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
			}

			span {
				font-size: 14px;

				B {
					display: block;
					font-size: 18px;
				}
			}
		}
	}
}