@import '../../../ruya-shared/shared/assets/mixins';

.Timeline {
	position: relative;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	&_Error {
		display: grid;
		justify-content: center;
		align-items: center;
		height: 100%;
		color: var(--error-color);
		padding: 10px;
	}

	// Session Item
	.SleepSession {
		background-color: rgb(255 255 255 / 30%);
		padding: 24px 12px;
		border-radius: 12px;
		box-shadow: 0px 2px 3px 0px #00000017;

		// Session Header
		&_Header {
			display: grid;
			grid-auto-flow: row;
			gap: 12px;
			margin-bottom: 24px;

			&_Title {
				color: #6f8495;
				text-align: center;
			}

			&_Details {
				display: grid;
				grid-auto-flow: column;
				align-items: center;
				gap: 12px;

				// Date
				&_Date {
					color: #8b9ba9;
					font-size: 14px;
					font-weight: 500;
				}
			}

			&_Actions {
				display: grid;
				grid-auto-flow: column;
				justify-content: end;
				gap: 12px;
			}
		}

		// Session Details
		&_Details {
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			align-items: center;
			background-color: #fff;
			box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.09);
			border-radius: 8px;
			padding: 18px;
			margin-bottom: 24px;
			font-size: 16px;

			&>div {
				display: grid;
				grid-auto-flow: column;
				gap: 8px;
				align-items: center;
			}

			.MaterialSymbol {
				color: #8594a4;
			}
		}

		// Session Dreams
		&_Dreams {
			display: grid;
			gap: 24px;
			margin-bottom: 24px;
		}

		// Session Actions
		&_Actions {
			display: grid;

			@include minTablet {
				justify-content: end;
			}
		}
	}

	&_Loading,
	&_LoadMore {
		display: grid;
		justify-content: center;
	}
}
