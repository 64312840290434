@import '../../../ruya-shared/shared/assets/mixins';
@import '../../../ruya-shared/shared/assets/variables';

.Footer {
	margin-top: 16px;

	&_Content {
		display: grid;
		grid-template-columns: min-content min-content;
		grid-template-areas:
			'FooterCenter FooterCenter'
			'FooterLogo FooterLogo';
		justify-content: space-between;
		align-items: center;
		max-width: $content-max-width;
		padding: $content-padding-mobile;
		margin: 40px auto;

		@include minDesktop {
			padding: $header-padding;
			grid-template-columns: min-content 1fr max-content;
			grid-template-areas: 'FooterLogo FooterCenter FooterLanguage';
			margin: 90px auto 60px auto;
		}
	}

	&_Center {
		grid-area: FooterCenter;
		display: grid;
		grid-auto-flow: row;
		gap: 24px;
	}

	&_Logo {
		display: grid;
		grid-area: FooterLogo;
		margin-top: 48px;
		justify-content: center;

		@include minDesktop {
			justify-content: unset;
			margin-top: unset;
		}
	}

	&_Language {
		display: none;
		grid-area: FooterLanguage;
		margin-top: 48px;

		@include minDesktop {
			display: block;
			margin-top: unset;
		}
	}

	&_Navigation {
		display: grid;
		list-style: none;
		padding: 0;
		gap: 24px;
		grid-auto-flow: row;
		justify-content: center;

		@include minTablet {
			grid-auto-flow: column;
		}

		li {
			a {
				display: block;
				color: var(--primary-color);
				font-size: 16px;
				font-weight: 500;
				line-height: 28px;
				text-align: center;

				&:hover {
					color: var(--highlight-color);
				}
			}
		}
	}

	&_CopyrightText {
		color: var(--primary-color);
		font-size: 16px;
		font-weight: 500;
		line-height: 28px;
		text-align: center;

		p {
			margin: 0;
			padding: 0;
		}
	}

	&_Social {
		display: grid;
		grid-auto-flow: column;
		justify-content: center;
		column-gap: 8px;
		align-items: center;

		a {
			text-decoration: none !important;

			span {
				display: grid;
				border-radius: 100px;
				border: 1px solid rgba(15, 54, 82, 0.2);
				width: 36px;
				height: 36px;
				align-items: center;
				justify-items: center;
				gap: 12px;
				cursor: pointer;
				text-decoration: none !important;

				@include minTablet {
					width: 54px;
				}

				svg {
					max-width: 16px;
					max-height: 16px;
					fill: var(--primary-color);
					text-decoration: none !important;
				}
			}

			&:hover {
				span {
					border: 1px solid #fff;
					background-color: #fff;

					svg {
						fill: var(--highlight-color);
					}
				}
			}
		}
	}
}
