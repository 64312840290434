.StackButtons {
	display: grid;

	// Gap
	&--gap-1 { gap:1px; }
	&--gap-2 { gap:2px; }
	&--gap-3 { gap:3px; }
	&--gap-4 { gap:4px; }
	&--gap-5 { gap:5px; }
	&--gap-6 { gap:6px; }
	&--gap-7 { gap:7px; }
	&--gap-8 { gap:8px; }
	&--gap-9 { gap:9px; }
	&--gap-10 { gap:10px; }

	// Radius
	&--radius-5 > * { border-radius: 1px; }
	&--radius-10 > * { border-radius: 10px; }
	&--radius-15 > * { border-radius: 15px; }
	&--radius-20 > * { border-radius: 20px; }
	&--radius-25 > * { border-radius: 25px; }
	&--radius-30 > * { border-radius: 30px; }
	&--radius-35 > * { border-radius: 35px; }
	&--radius-40 > * { border-radius: 40px; }
	&--radius-45 > * { border-radius: 45px; }
	&--radius-50 > * { border-radius: 50px; }
	&--radius-60 > * { border-radius: 60px; }
	&--radius-70 > * { border-radius: 70px; }
	&--radius-80 > * { border-radius: 80px; }
	&--radius-90 > * { border-radius: 90px; }
	&--radius-100 > * { border-radius: 100px; }

	// Horizontal
	&--horizontal {
		grid-auto-flow: column;

		& *:not(:first-child):not(:last-child) {
			border-radius: 0;
		}

		& *:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		& *:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	// Vertical
	&--vertical {
		grid-auto-flow: row;

		& *:not(:first-child):not(:last-child) {
			border-radius: 0;
		}

		& *:first-child {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		& *:last-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}