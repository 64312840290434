@import '../../../ruya-shared/shared/assets/mixins';

.Journal {

	&_NoRecords {
		display: grid;
		margin: 0 auto 30px auto;
		justify-content: center;
		gap: 40px;

		&_Step {
			text-align: center;
			background-color: #ffffff50;
			border-radius: 8px;
			padding: 10px;

			.P {
				max-width: 200px;
				margin: 0 auto 10px auto;
			}
		}

		&_Separator {
			background-color: unset;
			text-align: center;
		}

		&_Desktop {
			display: none;
		}

		.MaterialIcon {
			color: var(--primary-color);
		}

		&_Icon {
			color: var(--highlight-color);
		}

		@include minTablet {
			grid-template-columns: 1fr min-content 1fr min-content 1fr;
			align-items: center;
			margin: 48px auto;
			max-width: 900px;

			&_Step {
				text-align: left;

				.P {
					margin: 0 0 10px 0;
				}
			}

			&_Mobile {
				display: none;
			}

			&_Desktop {
				display: block;
			}
		}
	}

	&_New {
		display: grid;
		margin: 0 auto;
		justify-content: center;
	}
}