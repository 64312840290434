@import '../../../ruya-shared/shared/assets/mixins';

.Navigation {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: min-content 1fr;
	justify-content: end;
	align-items: center;
	padding: $header-padding-mobile;

	// Responsive desktop
	@include minDesktop {
		padding: $header-padding;
	}

	// Desktop main navigation
	&_Middle {
		display: none;
		text-align: center;
		height: 100%;
		align-items: center;

		@include minDesktop {
			display: grid;
		}

		&_Links {
			display: grid;
			grid-auto-flow: column;
			gap: 12px;
			align-content: center;
			justify-content: center;
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	&_End {
		display: grid;
		grid-auto-flow: column;
		text-align: end;
		align-items: center;
		height: 100%;

		&_Links {
			display: grid;
			grid-auto-flow: column;
			gap: 12px;
			align-content: center;
			justify-content: end;
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	.ReactLink {
		display: block;
		padding: 7px 24px;
		color: var(--primary-color);
		font-size: 16px;
		font-weight: 500;
		line-height: 28px;
		text-decoration: none;
		white-space: nowrap;
		border-radius: var(--form-button-border-radius);

		&:hover {
			background: var(--navigation-link-hover-bg);
			text-decoration: none;
		}

		&--active {
			background: #fff;

			&:hover {
				background: #fff;
			}
		}
	}

	.MainNavButton {
		display: block;
		border-radius: var(--form-button-border-radius);
		padding: 7px 24px;
		color: var(--primary-color);
		font-size: 16px;
		font-weight: 500;
		line-height: 28px;
	}

	.AccountButton {
		display: none;
		border-radius: var(--form-button-border-radius);
		color: var(--primary-color);
		font-size: 16px;
		font-weight: 500;
		line-height: 28px;
		background: var(--primary-color);
		color: #fff;
		white-space: nowrap;
		width: max-content;
		padding: 7px 24px;

		@include minDesktop {
			display: block;
		}

		&.ReactLink--active {
			background: var(--highlight-color);
		}

		&:hover {
			background: var(--highlight-color);
			text-decoration: none;
		}
	}

	.LanguageButton {
		display: none;
		padding: 0;

		@include minDesktop {
			display: block;
		}

		&>span {
			display: grid;
			grid-auto-flow: column;
			border-radius: var(--form-button-border-radius);
			gap: 8px;
			align-items: center;
			color: var(--primary-color);
			font-size: 16px;
			font-weight: 500;
			line-height: 28px;
			white-space: nowrap;
			padding: 7px 24px;
		}

		&_Flag {
			display: block;
			width: 24px;
			height: 18px;
			background-repeat: no-repeat;
		}
	}
}