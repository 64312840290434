.Interpreter {
	display: grid;
	padding: 24px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 2px 3px 0px hsla(231, 35%, 7%, 0.314);
	grid-template-rows: auto 1fr auto;
	height: 100%;
	align-content: start;
	gap: 24px;
	background-size: cover;
	min-height: 415px;

	&--back {
		background-color: rgb(240 253 247);
	}

	&_Header {
		display: grid;
		grid-template-columns: min-content 1fr;
		align-items: center;
		gap: 12px;

		&_Icon {
			display: grid;
			width: 60px;
			height: 60px;
			border-radius: 8px;
			font-size: 34px;
			background: #FCE0EA;
			color: var(--primary-color);
			align-items: center;
			text-align: center;
		}
	}

	&_Explanation {
		display: grid;

		&_Description {
			display: grid;
			grid-template-rows: 1fr min-content;

			&_Buttons {
				display: grid;
				gap: 12px;
			}
		}

		&_Details {
			display: grid;
			grid-template-rows: 1fr 1fr min-content;
		}

		.P {
			margin: 0 0 8px;

			span {
				font-weight: 700;
				display: block;
				font-size: 16px;
			}
		}
	}

	.Button {
		margin-top: 20px;
		justify-self: center;
		align-self: bottom;
	}
}