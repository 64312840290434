@import '../../../ruya-shared/shared/assets/mixins';

.SleepSessionsEdit {

	.react-datepicker {
		width: max-content;
	}

	&_Date {
		display: grid;
		align-items: center;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
	}

	&_Form {
		display: grid;
		width: 100%;
		max-width: 370px;
		margin: 0 auto;
		gap: 37px;
		margin-top: 100px;
	}

	&_Error {
		display: grid;
		grid-auto-flow: column;
		justify-content: start;
		gap:12px;
		color: var(--error-color);
	}

	&_Buttons {
		display: grid;
		grid-auto-flow: column;
		gap: 10px;
		justify-content: center;
		margin-top: 30px;
	}

	&_Delete {
		display: grid;
		border: var(--error-color) 1px solid;
		box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.09);
		border-radius: 8px;
		padding: 12px;
		gap: 12px;
		margin-top: 100px;
		justify-items: start;

		legend {
			text-shadow: 0px 0px 3px rgba(255, 255, 255, 1);
			color: var(--error-color);
		}

		.P {
			margin: 0;
		}
	}
}