@import '../../../ruya-shared/shared/assets/mixins';

.SignUp {

	&_ConnectWith {
		display: grid;
		justify-content: center;
		gap: 12px;

		@include minTablet {
			grid-auto-flow: column;
		}
	}

	&_OR_Email {
		text-align: center;
		margin-top: 40px;
		color: #8594A4;
	}

	&_SignIn {
		text-align: center;
		margin-bottom: 40px;

		.ReactLink {
			color: var(--highlight-color);
			display: block;

			@include minTablet {
				display: inline-block;
			}
		}
	}

	&_Form {
		position: relative;
		margin: 50px auto 0 auto;
		display: grid;
		grid-auto-flow: row;
		gap: 8px;
		width: 100%;
		max-width: 370px;

		@include addBubbles;
	}

	&_Error {
		text-align: center;
		font-size: 16px;
		color: var(--error-color);
		margin: 0;
	}

	&_CaptchaError {
		color: var(--warning-color);
		margin: 0;
	}

	&_TermsAndConditions {
		.Checkbox  {
			margin-top: 10px;
		}
	}
}
