.ToggleButton {
	align-items: center;
	background-color: var(--button-light-bg);
	border-radius: var(--button-sm-border-radius);
	border-width: var(--button-border-width);
	cursor: pointer;
	display: inline-grid;
	font-size: var(--button-sm-font-size);
	border-color: var(--button-light-border-color);
	border-style: solid;
	color: var(--button-light-color);
	height: var(--button-sm-height);
	padding: var(--button-sm-padding);

	.MaterialSymbol {
		color: var(--input-icon-color);
		font-size: var(--button-sm-icon-size);
	}

	&--checked {
		.MaterialSymbol {
			color: var(--highlight-color);
		}
	}

	&:hover {
		background-color: var(--button-light-bg-hover);
	}

	input {
		display: none;
	}
}