.Profile {
	max-width: 900px;

	&_Info {

		&_ShowQuestions {
			padding: 24px;
		}

		&_Questions {
			padding: 24px;
		}
	}


}