// Input Container
.InputWithButton {
	display: inline-block;
	border-radius: var(--border-radius);
	background: #fafafb;
	border: 1px solid rgba(255, 255, 255, 0);
	box-shadow: 0px 8px 16px 0px rgba(48, 49, 51, 0.09);
	padding: 12px 12px 12px 24px;

	&:focus-within {
		border: 1px solid rgba(255, 57, 116, 0.5);
		box-shadow: 0px 8px 16px 0px rgba(255, 57, 116, 0.2);
	}

	&_Error {
		color: var(--highlight-color);
		font-size: 14px;
		font-weight: 500;
	}

	&_Wrapper {
		display: grid;
		grid-template-columns: 1fr min-content;
		gap: 12px;
		height: 55px;
		width: 100%;

		input {
			border: none;
			box-shadow: none;
			height: 55px;
			padding: 0;
			font-size: 16px;
			color: var(--primary-color);
			font-weight: 500;
			background: transparent !important;
			width: 100%;
			// min-width: 95px;

			&:focus {
				outline: 0;
			}
		}

		button {
			display: inline-grid;
			grid-auto-flow: column;
			gap: 10px;
			border: 0;
			border-radius: var(--form-button-border-radius);
			background: var(--primary-color);
			padding: 18px 24px;
			margin: 0;
			box-shadow: none;
			color: #fff;
			font-size: 16px;
			font-weight: 600;
			align-items: center;
			text-decoration: none;
			text-wrap: nowrap;

			&:hover {
				text-decoration: none;
				background-color: var(--highlight-color);
				color: #fff;
			}

			&:focus {
				outline: 0;
			}
		}
	}
}
