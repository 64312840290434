.LanguageSelection {
	max-width: 1000px;
	margin: 0 auto;

	.InputWithButton {
		margin: 0 auto;
		display: block;
		width: max-content;
	}

	&_List {
		margin: 64px auto 0 auto;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 32px;
		list-style-type: none;
		padding: 0;

		li {
			span {
				display: grid;
				grid-auto-flow: column;
				gap: 8px;
				margin-bottom: 8px;
				cursor: pointer;
				justify-content: start;
				text-wrap: nowrap;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&_Current {
		display: grid;
		grid-auto-flow: column;
		gap: 8px;
		justify-content: left;

		div {
			font-weight: 600;
		}
	}

	&_Explanation {
		margin-bottom: 30px;
	}

	&_Flag {
		display: block;
	}

	&--1x1 {
		.LanguageSelection_Flag {
			width: 20px;
			height: 20px;
		}
	}

	&--4x3 {
		.LanguageSelection_Flag {
			width: 27px;
			height: 20px;
		}
	}
}
